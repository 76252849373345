import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/Layout"
import { Section, HeaderRender } from "../components/elements"
import { WorkSlider } from "../components/elements/WorkSlider"

const CommercialPage = ({ data }) => {
  const node = data.allWordpressPage.edges[0].node
  const images = data.allWordpressWpShowcaseWork.edges

  return (
    <Layout
      title={node.acf.seo_title}
      description={node.acf.seo_description}
      image={node.featured_media ? node.featured_media.source_url : null}
      bodyRender={() => <HeaderRender>{node.title}</HeaderRender>}
    >
      {() => (
        <>
          <Section>
            <div dangerouslySetInnerHTML={{ __html: node.content }}></div>
          </Section>
          {images.length > 0 && (
            <Section modifiers={"default"}>
              <WorkSlider slides={images} />
            </Section>
          )}
        </>
      )}
    </Layout>
  )
}

export default CommercialPage

export const commercialQuery = graphql`
  query CommercialPageQuery {
    allWordpressPage(filter: { slug: { eq: "commercial-work" } }) {
      edges {
        node {
          id
          title
          content
          path
          featured_media {
            source_url
          }
          acf {
            seo_title
            seo_description
          }
        }
      }
    }
    allWordpressWpShowcaseWork(
      filter: { acf: { showcase_type: { eq: "commercial" } } }
    ) {
      edges {
        node {
          title
          content
          featured_media {
            source_url
          }
          acf {
            showcase_type
          }
        }
      }
    }
  }
`
